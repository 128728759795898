<!-- Finder de Roles Usuarios-->

<template>
  <div class="rolesF" v-if="loaded">

    <div style="display:flex;justify-content:center">
      <v-sheet v-if="!viewMtoOnly" :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera">
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader">
          </baseHeader>
        </div>

        <!-- Contenido -->
        <div class="contenedor" style="width:640px">

          <!-- Grid -->
          <baseGridMD
            style="margin-top:10px"
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="-1"
            :expansible="isReadOnly"
            dense
            height="40rem"
            @onEvent="execAccion">

              <!-- Slot botonera Top -->
              <template v-slot:gridTop="{}">
                <div>

                  <baseBtraMto
                    :perm="permMto"
                    :modulo="btGridCfg"
                    @onEvent="execAccion">
                  </baseBtraMto>

                </div>
              </template>


              <!-- Prima única -->
              <!-- <template v-slot:pun="{ item }">
                <v-icon v-if="item.pun=='1'" small style="color:green">{{ 'mdi-check' }}</v-icon>
                <v-icon v-else small style="color:red"></v-icon>
              </template> -->

              <!-- Slot panel expansible -->
              <template v-slot:expansibleMX="{}">
                <rolesMX
                  sync
                  syncUpdate
                  :syncAccion="syncAccionSend"
                  :storeRaiz="storeName"
                  :masterStore="storeName"
                  :readOnly="readOnly"
                  :viewMtoOnly="viewMtoOnly">
                </rolesMX>
              </template>
          </baseGridMD>
        </div>
      </v-sheet>
    </div>
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto";
  import rolesMX from '@/components/rolesMX';

  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraMto, rolesMX },
    props: {},

    data() {
      return {
        stIni: {
          api: "rolesF",
          titulo:"·Roles Usuarios",
          name:"rolesF",
          mView:'rolesM',
          pView:[],
          expansibleMX:'rolesMX'
        }
      };
    },

    methods: {

      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        this.headers = [{
          name:[],
          fields:[
            { text: "Nombre", value: "name", width: "90%" },
            { text: "ID", value: "id", width: "10%" },
            // { text: "Prima Única", value: "pun", width: "20%", slot:true },
          ]}
        ];

        this.headerGrid= this.headerSet();

        this.autoLoad= 'buscar';

        // this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo.FM));
      },

    },


    computed: {
        isReadOnly() {
          if (!this.readOnly) return `${this.storeName}${this.stIni.mView}`;
          return '';
        }
      }
  };
</script>
